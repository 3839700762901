@keyframes __commonLoaderAnimation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.__commonLoader {
  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 6px solid transparent;
    border-top-color: #334;
    border-bottom-color: #334;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    animation: __commonLoaderAnimation ease-in-out 1s infinite;
  }
}
