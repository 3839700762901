.page-footer {
  position: relative;

  .footer-top {
    position: relative;

    @media(max-width: 1023px) {
      padding: 0;
      margin-top: 24px;
  
      &:after {
        content: '';
        position: relative;
        display: block;
        width: 100%;        
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        background-image: url(/assets/images/footer-badge-mobile.svg);
        padding-bottom: 21.5%;
        margin-top: 12px;
      }
    }

    @media(min-width: 1024px) {
      padding: 1% 0;

      margin-top: 24px;
  
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        background-image: url(/assets/images/footer-badge.svg);
        padding-bottom: 6.5%;
        transform: translateY(-50%);
        z-index: 0;
      }
    }
  }

  .footer-body {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
      background-image: url(/assets/images/footer-pattern.png);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;
    }
  }
}