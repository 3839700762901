@keyframes hide-menu {
  0%   {
    position: sticky;
    transform: translateY(0);
  }
  50%   {
    position: sticky;
    transform: translateY(-100%);
    opacity: 0;
  }
  51%   {
    transform: translateY(0);
  }
  66%   {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-menu {
  0%   {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.stickyBar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
  opacity: 0;
  transition: opacity .2s;
}
.stickyPlaceholder {
  display: block;
  height: 0;
  overflow: hidden;
  height: 0;
  background-color: white;
  transition: height .3s;
}

.header {
  box-shadow: 0 10px 10px rgba(58, 71, 89, 0.05);
  &.trans {
    box-shadow: none;
  }
}
.top-bar {
  position: sticky;
  display: block;
  top: 0;
  z-index: 99;
  opacity: 1;
  transform: translateY(0);
  
  &.hidden-menu {
    position: relative;
    transform: translateY(0);
    opacity: 1;

    &.animate {
      animation-name: hide-menu;
      animation-duration: .4s;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  
  &.visible-menu {
    position: sticky;
    transform: translateY(0);
    opacity: 1;

    &.animate {
      animation-name: show-menu;
      animation-duration: .2s;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}