.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 998;
  transition: opacity .3s, width 0s .3s, height 0s .3s;
  pointer-events: none;
  
  &.active {
    pointer-events: all;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity .3s, width 0s, height 0s;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--tablet-sm-menu-width);
  max-width: 95%;
  max-width: calc(100% - 50px);
  background-color: var(--white);
  z-index: 999;
  overflow: auto;
  transform: translateX(-100%);
  transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);

  &.active {
    transform: translateX(0);
    box-shadow: 0 0 18px rgba(0, 0, 0, .15);
  }

  .btn-whatsapp {
    border-color: rgb(103, 193, 94);
    color: rgb(103, 193, 94);
  }
  .btn-contact {
    border-color: var(--lava);
    color: var(--lava);
  }
  .btn-whatsapp, .btn-contact {
    font-size: 10px;
  }

  .body {
    .buttons {
      background-color: var(--alice-blue);

      li {
        border-bottom: 1px solid rgba(var(--cadet-gray-value), 0.1);
        transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);

        span.icon {
          font-size: 24px;
        }

        span,
        span.icon *,
        span.icon *:before {
          transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &:not(.active) {
          span,
          span.icon *,
          span.icon *:before {
            color: var(--cadet-gray);
            fill: var(--cadet-gray);
            opacity: .95
          }
        }

        &.active {
          background-color: var(--white);

          span,
          span.icon *,
          span.icon *:before {
            opacity: 1;
          }
        }
      }
    }

    .content {

      .subs {
        position: relative;
        .item {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          z-index: 0;
          transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;

          &.active {
            pointer-events: all;
            z-index: 1;
            opacity: 1;
          }

          h2 {
            a {
              color: var(--lava);
            }
          }

          ul {
            li {
              a.strong {
                color: var(--lava);
              }
            }
          }
        }
      }
    }
  }  
}