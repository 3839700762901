.our-packages {
  .heading {
    color: var(--lava);

    :is(small) {
      display: block;
      color: var(--honolulu-blue);
    }
  }

  .package-list {
    .package-list-container {
      position: relative;
    }

    .load-more {
      position: relative;
    }
  }
}