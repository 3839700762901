@font-face {
  font-family: 'dp-icons';
  src:  url('../fonts/dp-icons/dp-icons.eot');
  src:  url('../fonts/dp-icons/dp-icons.eot') format('embedded-opentype'),
    url('../fonts/dp-icons/dp-icons.ttf') format('truetype'),
    url('../fonts/dp-icons/dp-icons.woff') format('woff'),
    url('../fonts/dp-icons/dp-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.dp {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dp-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dp-helpdesk:before {
  content: "\e981";
}
.dp-mobile:before {
  content: "\e980";
}
.dp-trash:before {
  content: "\e97f";
}
.dp-card:before {
  content: "\e978";
}
.dp-copy:before {
  content: "\e979";
}
.dp-send-money:before {
  content: "\e97a";
}
.dp-shopping-bag:before {
  content: "\e97b";
}
.dp-summary:before {
  content: "\e97c";
}
.dp-user:before {
  content: "\e97d";
}
.dp-wallet:before {
  content: "\e97e";
}
.dp-navicons-appstore:before {
  content: "\e977";
}
.dp-navicons-success:before {
  content: "\e96e";
  color: #98a8b4;
}
.dp-navicons-all-packages .path1:before {
  content: "\e96f";
  color: rgb(204, 23, 27);
}
.dp-navicons-all-packages .path2:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(107, 147, 214);
  opacity: 0.3;
}
.dp-navicons-help .path1:before {
  content: "\e971";
  color: rgb(107, 147, 214);
  opacity: 0.3;
}
.dp-navicons-help .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(204, 23, 27);
}
.dp-navicons-help .path3:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(204, 23, 27);
}
.dp-navicons-phone:before {
  content: "\e974";
  color: var(--lava);
}
.dp-navicons-sample-videos .path1:before {
  content: "\e975";
  color: rgb(107, 147, 214);
  opacity: 0.3;
}
.dp-navicons-sample-videos .path2:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(204, 23, 27);
}
.dp-download:before {
  content: "\e96d";
}
.dp-cozucu-colorful .path1:before {
  content: "\e967";
  color: rgb(255, 255, 255);
}
.dp-cozucu-colorful .path2:before {
  content: "\e968";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-cozucu-colorful .path3:before {
  content: "\e969";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-cozucu-colorful .path4:before {
  content: "\e96a";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-cozucu-colorful .path5:before {
  content: "\e96b";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-cozucu-colorful .path6:before {
  content: "\e96c";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-d-quote:before {
  content: "\e965";
}
.dp-drum:before {
  content: "\e966";
}
.dp-br-tablet .path1:before {
  content: "\e94f";
  color: #2C95AC;
}
.dp-br-tablet .path2:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-recycle .path1:before {
  content: "\e951";
  color: rgb(255, 255, 255);
}
.dp-br-recycle .path2:before {
  content: "\e952";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-br-recycle .path3:before {
  content: "\e953";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-br-recycle .path4:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-play .path1:before {
  content: "\e955";
  color: #2C95AC;
}
.dp-br-play .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-graph-bar .path1:before {
  content: "\e957";
  color: rgb(255, 255, 255);
}
.dp-br-graph-bar .path2:before {
  content: "\e958";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-br-expert .path1:before {
  content: "\e959";
  color: #2C95AC;
}
.dp-br-expert .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-doc .path1:before {
  content: "\e95b";
  color: #2C95AC;
}
.dp-br-doc .path2:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-doc .path3:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-chat .path1:before {
  content: "\e95e";
  color: #2C95AC;
}
.dp-br-chat .path2:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-badge .path1:before {
  content: "\e960";
  color: #2C95AC;
}
.dp-br-badge .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-br-badge .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: #2C95AC;
}
.dp-br-ai .path1:before {
  content: "\e963";
  color: #2C95AC;
}
.dp-br-ai .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-star:before {
  content: "\e94e";
}
.dp-cozucu-emblem-colourful .path1:before {
  content: "\e944";
  color: rgb(0, 80, 125);
}
.dp-cozucu-emblem-colourful .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.dp-cozucu-emblem-colourful .path3:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.dp-cozucu-emblem-colourful .path4:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.dp-cozucu-emblem-colourful .path5:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 80, 125);
}
.dp-doping-emblem-colourful .path1:before {
  content: "\e949";
  color: rgb(205, 34, 39);
}
.dp-doping-emblem-colourful .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(205, 34, 39);
}
.dp-doping-emblem-colourful .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(205, 34, 39);
}
.dp-doping-emblem-colourful .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(205, 34, 39);
}
.dp-doping-emblem-colourful .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(17, 88, 131);
}
.dp-bip-white .path1:before {
  content: "\e93a";
  color: rgb(255, 255, 255);
}
.dp-bip-white .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-bip-white .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(200, 218, 234);
}
.dp-bip-original .path1:before {
  content: "\e93d";
  color: rgb(57, 60, 83);
}
.dp-bip-original .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(57, 60, 83);
}
.dp-bip-original .path3:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 216, 38);
}
.dp-telegram-original .path1:before {
  content: "\e940";
  color: rgb(0, 0, 0);
}
.dp-telegram-original .path2:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(200, 218, 234);
}
.dp-telegram-original .path3:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(169, 198, 216);
}
.dp-telegram-original .path4:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-phone .path1:before {
  content: "\e932";
  color: rgb(169, 198, 216);
}
.dp-phone .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(200, 218, 234);
}
.dp-phone .path3:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-telegram-white .path1:before {
  content: "\e935";
  color: rgb(200, 218, 234);
}
.dp-telegram-white .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(169, 198, 216);
}
.dp-telegram-white .path3:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-whatsapp-white .path1:before {
  content: "\e938";
  color: rgb(200, 218, 234);
}
.dp-whatsapp-white .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255); 
}
 .dp-whatsapp-original:before {
  content: "\e931";
  color: #67c15e;
} 
.dp-mc-envelope .path1:before {
  content: "\e92f";
  color: rgb(200, 218, 234);
}
.dp-mc-envelope .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.dp-ai:before {
  content: "\e900";
}
.dp-arrow-circle-down:before {
  content: "\e901";
}
.dp-arrow-circle-left:before {
  content: "\e902";
}
.dp-arrow-circle-right:before {
  content: "\e903";
}
.dp-arrow-circle-up:before {
  content: "\e904";
}
.dp-arrow-down:before {
  content: "\e905";
}
.dp-arrow-fill-circle-down:before {
  content: "\e906";
}
.dp-arrow-fill-circle-left:before {
  content: "\e907";
}
.dp-arrow-fill-circle-right:before {
  content: "\e908";
}
.dp-arrow-fill-circle-up:before {
  content: "\e909";
}
.dp-arrow-left:before {
  content: "\e90a";
}
.dp-arrow-right:before {
  content: "\e90b";
}
.dp-arrow-up:before {
  content: "\e90c";
}
.dp-backspace:before {
  content: "\e90d";
}
.dp-basket:before {
  content: "\e90e";
}
.dp-calculator:before {
  content: "\e90f";
}
.dp-calendar:before {
  content: "\e910";
}
.dp-chat:before {
  content: "\e911";
}
.dp-check:before {
  content: "\e912";
}
.dp-close:before {
  content: "\e913";
}
.dp-cozucu:before {
  content: "\e914";
}
.dp-direction:before {
  content: "\e915";
}
.dp-envelope:before {
  content: "\e916";
}
.dp-equal:before {
  content: "\e917";
}
.dp-eye-close:before {
  content: "\e918";
}
.dp-eye-open:before {
  content: "\e919";
}
.dp-facebook:before {
  content: "\e91a";
}
/* .dp-hamburger:before {
  content: "\e91b";
} */
.dp-home:before {
  content: "\e91c";
}
.dp-info-tip:before {
  content: "\e91d";
}
.dp-instagram:before {
  content: "\e91e";
}
.dp-linkedin:before {
  content: "\e91f";
}
.dp-lock:before {
  content: "\e920";
}
.dp-math:before {
  content: "\e921";
}
.dp-minus:before {
  content: "\e922";
}
.dp-package:before {
  content: "\e923";
}
.dp-pen:before {
  content: "\e924";
}
.dp-play:before {
  content: "\e925";
}
.dp-plus:before {
  content: "\e926";
}
.dp-refund:before {
  content: "\e927";
}
.dp-search:before {
  content: "\e928";
}
.dp-search-article:before {
  content: "\e929";
}
.dp-timer:before {
  content: "\e92a";
}
.dp-twitter:before {
  // content: "\e92b";
  content: "\e982";
}
.dp-warning:before {
  content: "\e92c";
}
.dp-doping-emblem:before {
  content: "\e92d";
}
.dp-youtube:before {
  content: "\e92e";
}
.dp-x:before {
  content: "\e982";
}
.dp-tiktok:before {
  content: "\e983";
}
.dp-pencil:before {
  content: "\e985";
}

.dp-animasyonlu-anlatim-icon:before {
  content: "\e984";
  color: #2c95ac;
}
.dp-calisma-programi-icon .path1:before {
  content: "\e986";
  color: rgb(44, 149, 172);
}
.dp-calisma-programi-icon .path2:before {
  content: "\e987";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path3:before {
  content: "\e988";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path4:before {
  content: "\e989";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path5:before {
  content: "\e98a";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-calisma-programi-icon .path6:before {
  content: "\e98b";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-calisma-programi-icon .path7:before {
  content: "\e98c";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-calisma-programi-icon .path8:before {
  content: "\e98d";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path9:before {
  content: "\e98e";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path10:before {
  content: "\e98f";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path11:before {
  content: "\e990";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path12:before {
  content: "\e991";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path13:before {
  content: "\e992";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path14:before {
  content: "\e993";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path15:before {
  content: "\e994";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-calisma-programi-icon .path16:before {
  content: "\e995";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-kocum-yanimda-icon .path1:before {
  content: "\e996";
  color: rgb(255, 255, 255);
}
.dp-kocum-yanimda-icon .path2:before {
  content: "\e997";
  margin-left: -1.2373046875em;
  color: rgb(44, 149, 172);
}
.dp-simdi-anladim-icon .path1:before {
  content: "\e998";
  color: rgb(255, 255, 255);
}
.dp-simdi-anladim-icon .path2:before {
  content: "\e999";
  margin-left: -0.947265625em;
  color: rgb(255, 255, 255);
}
.dp-simdi-anladim-icon .path3:before {
  content: "\e99a";
  margin-left: -0.947265625em;
  color: rgb(255, 255, 255);
}
.dp-simdi-anladim-icon .path4:before {
  content: "\e99b";
  margin-left: -0.947265625em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path1:before {
  content: "\e99c";
  color: rgb(255, 255, 255);
}
.dp-video-cozumlu-soru-bankasi-icon .path2:before {
  content: "\e99d";
  margin-left: -1.0263671875em;
  color: rgb(255, 255, 255);
}
.dp-video-cozumlu-soru-bankasi-icon .path3:before {
  content: "\e99e";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path4:before {
  content: "\e99f";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path5:before {
  content: "\e9a0";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path6:before {
  content: "\e9a1";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path7:before {
  content: "\e9a2";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path8:before {
  content: "\e9a3";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path9:before {
  content: "\e9a4";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
.dp-video-cozumlu-soru-bankasi-icon .path10:before {
  content: "\e9a5";
  margin-left: -1.0263671875em;
  color: rgb(44, 149, 172);
}
