.desktop-menu {
  background-color: var(--white);
  color: var(--charcoal);

  --pers: 15rem;
  --rx: -3deg;

  a {
    color: inherit;
    transition: color .2s !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 500;

    &:hover {
      color: var(--lava) !important;
    }
    
    &.exact-match {
      color: var(--lava) !important;
    }

    &.active {
      color: rgba(var(--lava-value), .85) !important;
    }
  }

  &.transparent {
    background-color: transparent;
    color: rgba(var(--white-value), 0.7);

    a:hover, a.exact-match, a.active {
      color: var(--white);
    }

    .logo {
      filter: brightness(0) invert(100%);
    }
  }

  .has-submenu {
    position: relative;
    overflow: visible;
    perspective-origin: top;
    perspective: var(--pers);

    .submenu {
      position: absolute;
      background-color: transparent;
      height: 0;
      left: 50%;
      right: auto;
      transform: rotateX(var(--rx)) translateX(-50%);
      opacity: 0;
      padding-top: 12px;
      transition: opacity .4s, transform .4s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      pointer-events: none;

      a {
        color: var(--charcoal);
        &:hover, &.exact-match {
          color: var(--lava);
        }
        &.active {
          color: rgba(var(--lava-value), .85);
        }
      }
  
      ul {
        position: relative;
        background-color: #fff;
        min-width: max-content;
        border-radius: 8px;
        z-index: 1;
        padding: 10px 0;
        filter: drop-shadow(0 3px 10px rgba(var(--charcoal-value), 0.4));
  
        li {
          position: relative;
          width: 100%;
          min-width: 250px;
  
          a {
            position: relative;
            display: block;
            padding: 15px 30px;
            background-color: transparent;
            transition: background-color .2s;
            width: 100%;
    
            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 0;
              background-color: var(--lava);
              transition: width 0s;
            }
            
            &:hover, &.exact-match, &.active {
              background-color: var(--alice-blue);
            }
            
            &:hover {
              &:before {
                transition: width .2s;
                width: 5px;
              }
            }
          }
        }
    
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -6px;
          right: 0;
          left: 0;
          margin: auto;
          width: 18px;
          height: 18px;
          border-radius: 3px;
          transform: rotateZ(45deg);
          background-color: #fff;
        }
      }
  
      &.right {
        left: auto;
        right: 0;
        transform: rotateX(var(--rx)) translateX(0);
        ul:before {
          right: 12px;
          left: auto;
        }
      }
  
      &.left {
        left: 0;
        right: auto;
        transform: rotateX(var(--rx)) translateX(0);
        ul:before {
          right: auto;
          left: 12px;
        }
      }
    }
    
    &:hover {
      .submenu {
        opacity: 1;
        transform: rotateX(0) translateX(-50%);
        &.right, &.left { transform: rotateX(0) translateX(0); }
        transition: opacity .4s, transform .4s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        pointer-events: all;
      }
    }
  }
}