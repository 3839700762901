.desktop-contact-bar {
  background-color: var(--honolulu-blue);
  color: var(--white);
  transition: all .2s;
  
  &.transparent {
    background-color: rgba(var(--gunmetal-value), 0.4);
  }
}

.mobile-contact-bar {
  background-color: var(--white);
  color: var(--charcoal-value);
  transition: all .2s;
  
  .mobile-logo {
    max-height: 60px;
    width: auto;
    height: auto;
  }

  .filter-dark {
    filter: brightness(0);
  }
  
  &.transparent {
    color: var(--white);
    background-color: transparent; //rgba(var(--gunmetal-value), 0.4);

    .trans-invert {
      filter: brightness(0) invert(100%);
    }
  }
}